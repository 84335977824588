import { LoadingButton } from "@mui/lab";
import * as Sentry from "@sentry/react";
import { Card, Typography, Stack, Alert, Link } from "@mui/material";
import messages from "./messages";
import { useIntl } from "react-intl";
import { useParams, useSearchParams } from "react-router-dom";
import React, { useState } from "react";
import { oidcLogin } from "@/hooks/login";
import getProvider from "@/utils/getProvider";
import { SSOProvider } from "@/types";
import IgniteLogo from "@/assets/images/logo-long.svg";

const TERMS_LINK = "https://www.igniteprocurement.com/security-and-legal/terms-and-conditions";

const SignupCard = ( { children}: { children: React.ReactNode }) => {
  const { formatMessage } = useIntl();
  return (
      <Stack alignItems="center" gap={4}>
          <a href="https://igniteprocurement.com/" rel="noreferrer" target="_blank">
              <img width="168px" src={IgniteLogo} alt="Ignite Procurement" />
          </a>
          <Card sx={{ boxShadow: 4, px: 9, py: 6, pt: 8 }}>
                <Stack justifyItems="start" alignItems="stretch" gap={2} maxWidth={350} minHeight={388}>
                    <Stack alignSelf="start" alignItems="start" gap={0.5} mb={8}>
                        <Typography variant="h6" color="black">
                            {formatMessage(messages.title)}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            {formatMessage(messages.description)}
                        </Typography>
                    </Stack>

                  {children}

                  <Typography variant="caption" sx={{ pt: 1 }}>
                      {formatMessage(messages.termsAndConditions, {
                          termsLink: (
                              <Link href={TERMS_LINK} target="_blank">
                                  {formatMessage(messages.termsLink)}
                              </Link>
                          ),
                      })}
                  </Typography>
              </Stack>
          </Card>
      </Stack>
  );
};

export const SSOSignup: React.FC = () => {
    const { formatMessage } = useIntl();
    const { ssoProvider } = useParams<{ ssoProvider: string }>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [searchParams] = useSearchParams();

    const email = searchParams.get("email");
    const provider = getProvider(ssoProvider ?? "");

    if (!provider) {
        return (
            <SignupCard>
                <Alert severity="error" sx={{ width: "100%"}}>
                    {formatMessage(messages.invalidProvider, { provider: ssoProvider })}
                </Alert>
            </SignupCard>
        );
    }

    async function ssoLogin(provider: SSOProvider, email: string | null) {
        setLoading(true);
        const { data, error } = await oidcLogin(email, provider, undefined);
        if (error) {
            setLoading(false);
            if (error.details) {
                setError(error.details);
            } else {
                Sentry.setContext("error", error);
                Sentry.captureMessage("failed to login with sso provider");
                setError("Unknown error");
            }
            return;
        }
        window.location.href = data.redirect;
    }

    return (
        <SignupCard >
            { error ? (
                <Alert severity="error" sx={{ width: "100%"}}>
                    {error}
                </Alert>
            ) : (
                <LoadingButton
                    fullWidth
                    startIcon={<img width={24} src={provider.iconSrc} />}
                    onClick={() => ssoLogin(provider, email)}
                    variant="outlined"
                    color="secondary"
                    loading={loading}
                >
                    <span>{formatMessage(messages.continueWith, { provider: provider.name })}</span>
                </LoadingButton>
            )}
        </SignupCard>
    );
};

export default SSOSignup;
