import { defineMessages } from "react-intl";

const messages = defineMessages({
    labelLanguage: {
        id: "labelLanguage",
        defaultMessage: "Language",
    },
    email: {
        id: "email",
        defaultMessage: "Email",
    },
    labelEmail: {
        id: "labelEmail",
        defaultMessage: "Email",
    },
    labelLastname: {
        id: "labelLastname",
        defaultMessage: "Last name",
    },
    labelFirstname: {
        id: "labelFirstname",
        defaultMessage: "First name",
    },
    languageUpdateFailed: {
        defaultMessage: "Failed to change language",
        id: "languageUpdateFailed",
    },
    languageUpdated: {
        id: "languageUpdated",
        defaultMessage: "Successfully changed language",
    },
    save: {
        defaultMessage: "save",
        id: "save",
    },
    initSettingsFlowError: {
        defaultMessage: "failed to initialize settings flow",
        id: "initSettingsFlowError",
    },
    linkStatusDelayed: {
        defaultMessage: "Failed to get account link status. Please try again later.",
        id: "linkStatusDelayed",
    },
    alertTimeout: {
        defaultMessage: "The operation timed out. Please try again later. Our team has been notified.",
        id: "alertTimeout",
    },
    alertDelayed: {
        defaultMessage: "this took longer than expected...",
        id: "alertDelayed",
    },
    network_error: {
        defaultMessage: "A network error occurred. Please try again later.",
        id: "network_error",
    },
    linkSuccessHref: {
        defaultMessage: "Go back to app",
        id: "linkSuccessHref",
    },
    passwordDetailText: {
        defaultMessage: "Update your password here.",
        id: "passwordDetailText",
    },
    updatePasswordTooltip: {
        defaultMessage: "Update your password here.",
        id: "updatePasswordTooltip",
    },
    emailHeader: {
        defaultMessage: "Email",
        id: "Email",
    },
    sidebarAuthentication: {
        defaultMessage: "Authentication",
        id: "Authentication",
    },
    sidebarProfile: {
        id: "sidebarProfile",
        defaultMessage: "Profile",
    },
    profileHeader: {
        id: "profileHeader",
        defaultMessage: "Your profile",
    },
    passwordSettingsHeader: {
        id: "passwordSettingsHeader",
        defaultMessage: "Update password",
    },
    updatePasswordButton: {
        id: "updatePasswordButton",
        defaultMessage: "Update password",
    },
    passwordNotMatch: {
        id: "passwordNotMatch",
        defaultMessage: "Passwords do not match",
    },
    linkSuccess: {
        id: "linkSuccess",
        defaultMessage: "Successfully linked your account to Microsoft. You can now log in using Social-Sign In.",
    },
    genericError: {
        id: "genericError",
        defaultMessage: "An error occurred. Please try again later.",
    },
    session_refresh_required: {
        id: "session_refresh_required",
        defaultMessage: "Session refresh required. Please log out and log in again.",
    },
    newVersionAvailable: {
        id: "app.newVersionAvailable",
        defaultMessage:
            "A new version of Ignite Procurement is available. The new version will be loaded when all Ignite Procurement tabs are closed.",
    },
    profileUpdated: {
        id: "profileUpdated",
        defaultMessage: "Profile successfully updated",
    },
    passwordUpdated: {
        id: "passwordUpdated",
        defaultMessage: "Password successfully updated",
    },
    password_too_common: {
        id: "password_too_common",
        defaultMessage: "This password is not secure or is too common",
    },
    session_not_found: {
        id: "session_not_found",
        defaultMessage: "Session not found. Please log out and log in again.",
    },
    unknown_error: {
        id: "unknown_error",
        defaultMessage: "An unknown error occurred. Please try again later.",
    },
    generic_error: {
        id: "generic_error",
        defaultMessage: "An error occurred. Please try again later.",
    },
    setPasswordFail: {
        defaultMessage: "Failed to update password. Our team has been notified.",
        id: "setPasswordFail",
    },
    tooltipText: {
        defaultMessage: "enable social sign in by clicking here",
        id: "tooltipText",
    },
    unlinkSuccess: {
        defaultMessage: "Successfully unlinked to {name}",
        id: "unlinkSuccess",
    },
    body: {
        defaultMessage:
            "You can link your account with other services to make it easier to log in. If you belong to a company that requires social sign in, you can enable it here.",
        id: "body",
    },
    title: {
        defaultMessage: "Social Sign in",
        id: "Social Sign in",
    },
    dialogLinkTitle: {
        defaultMessage: "Link your account to {name}?",
        id: "dialogLinkTitle",
    },
    dialogUnlinkTitle: {
        defaultMessage: "Unlink {name}?",
        id: "dialogUnlinkTitle",
    },
    dialogLinkBody: {
        defaultMessage: "By enabling this, you can log in using {name}. You will still keep your password.",
        id: "dialogLinkBody",
    },
    dialogUnlinkBody: {
        defaultMessage:
            "By disabling this, you will no longer be able to log in using {name}. You will still keep your password.",
        id: "dialogUnlinkBody",
    },
    providerTooltip: {
        id: "providerTooltip",
        defaultMessage: "By enabling this, you can log in using {name}. You will still keep your current password.",
    },
    scimmed: {
        id: "scimmed",
        defaultMessage: "Your user is externally managed. Data and roles can only be changed in Microsoft Entra ID (previously Azure AD)",
    },
    "4000005": {
        id: "4000005",
        defaultMessage:
            "This password is too weak. Please choose a password that is at least 8 characters. It must not be a common password.",
    },
});

export default messages;
