import { Navigate, createBrowserRouter } from "react-router-dom";

import EmailConfirmationModal from "@/components/EmailConfirmationModal";
import Layout from "@/components/Layout";
import RequireAuth from "@/components/RequireAuth";
import ErrorElement from "@/components/ErrorElement";

import { ConfiguringPage } from "@/pages/configuring";
import ErrorPage from "@/pages/errors";
import FlowError from "@/pages/FlowError";
import LinkRequiredPage from "@/pages/link-required";
import LoginPage from "@/pages/login";
import RecoveryPage from "@/pages/recovery";
import RefreshPage from "@/pages/session-refresh";
import RegisterCompanyPage from "@/pages/register-company";
import RegisterPage from "@/pages/register";
import VerifyPage from "@/pages/verification";
import SSOSignup from "@/pages/sso-signup";
import { SettingsPage } from "./pages/settings";
import ProfilePage from "./pages/settings/ProfilePage/index.tsx";
import AuthenticationPage from "./pages/settings/AuthenticationPage/index.tsx";
import { NoWorkspacesPage } from "./pages/no-workspaces/index.tsx";
import { OnboardingPage } from "./pages/onboarding/index.tsx";

import App from "./App.tsx";
import { LinkAccountPage } from "./pages/link-account/index.tsx";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorElement />,
        children: [
            {
                path: "/",
                element: <Navigate to="/login" />,
            },
            {
                path: "/login",
                element: (
                    <Layout hideLogo>
                        <LoginPage />
                    </Layout>
                ),
            },
            { path: "/errors", element: <ErrorPage flowType="error" /> },
            { path: "/ui/register", element: <ErrorPage flowType="registration" /> },
            { path: "/ui/recovery", element: <ErrorPage flowType="recovery" /> },
            { path: "/ui/login", element: <FlowError dest="/login" /> },

            {
                path: "/ssosignup/:ssoProvider",
                element: (
                    <Layout hideLogo>
                        <SSOSignup />
                    </Layout>
                ),
            },

            {
                path: "/session-refresh",
                element: (
                    <RequireAuth>
                        <RefreshPage />
                    </RequireAuth>
                ),
            },
            {
                path: "/link-required",
                element: (
                    <Layout>
                        <LinkRequiredPage />
                    </Layout>
                ),
            },
            {
                path: "/link-account",
                element: (
                    <RequireAuth>
                        <Layout>
                            <LinkAccountPage />
                        </Layout>
                    </RequireAuth>
                ),
            },
            {
                path: "/register",
                element: (
                    <Layout>
                        <RegisterPage />
                    </Layout>
                ),
            },
            {
                path: "/verification",
                element: (
                    <Layout>
                        <VerifyPage />
                    </Layout>
                ),
            },
            {
                path: "/verification-complete",
                element: (
                    <Layout>
                        <EmailConfirmationModal />
                    </Layout>
                ),
            },
            {
                path: "/recovery",
                element: (
                    <Layout>
                        <RecoveryPage />
                    </Layout>
                ),
            },
            {
                path: "/recovery-complete",
                element: (
                    <Layout>
                        <EmailConfirmationModal />
                    </Layout>
                ),
            },
            {
                path: "/no-workspaces",
                element: (
                    <Layout>
                        <NoWorkspacesPage />
                    </Layout>
                ),
            },
            {
                path: "/register-company",
                element: (
                    <Layout>
                        <RegisterCompanyPage />
                    </Layout>
                ),
            },
            {
                path: "/configuring",
                element: (
                    <Layout>
                        <ConfiguringPage />
                    </Layout>
                ),
            },
            {
                path: "/onboarding",
                element: <OnboardingPage />,
            },
            {
                path: "/settings",
                element: (
                    <RequireAuth>
                        <SettingsPage />
                    </RequireAuth>
                ),
                children: [
                    {
                        path: "/settings",
                        element: <Navigate to="/settings/authentication" />,
                    },
                    {
                        path: "/settings/profile",
                        element: <ProfilePage />,
                    },
                    {
                        path: "/settings/authentication",
                        element: <AuthenticationPage />,
                    },
                ],
            },
        ],
    },
]);
