import { EyeSlashLineRound, EyeLineRound } from "@ignite-analytics/icons";
import { IconButton, TextField } from "@mui/material";
import { useState } from "react";

type Props = {
    autoComplete?: string;
    placeholder?: string;
    label?: string;
    name?: string;
    required?: boolean;
    onChange?: (pw: string) => void;
    autofocus?: boolean;
    disabled?: boolean;
}
export const VisibilityTextField = ({ autofocus, autoComplete, placeholder, label, name, required, onChange, disabled }: Props) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <TextField
            disabled={disabled}
            autoComplete={autoComplete}
            placeholder={placeholder}
            label={label}
            name={name}
            variant="standard"
            onChange={(e) => {
                onChange?.(e.target.value);
            }}
            id="passwordField"
            type={showPassword ? "text" : "password"}
            fullWidth
            required={required}
            autoFocus={autofocus}
            sx={{ mb: 2 }}
            InputProps={{
                endAdornment: (
                    <IconButton
                        tabIndex={-1} // prevent focus at expense of accessibility
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((s) => !s)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                    >
                        {showPassword ? <EyeLineRound /> : <EyeSlashLineRound />}
                    </IconButton>
                ),
            }}
        />
    );
};


