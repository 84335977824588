import { defineMessages } from "react-intl";

const messages = defineMessages({
    header: {
        id: "register-company.header",
        defaultMessage: "Register a new company",
    },
    description: {
        id: "register-company.description",
        defaultMessage: "Get started by registering a new company below and start your free trial",
    },
    companyNameLabel: {
        id: "register-company.companyNameLabel",
        defaultMessage: "Company name",
    },
    companyIndustryLabel: {
        id: "register-company.companyIndustryLabel",
        defaultMessage: "Select an industry",
    },
    submitButton: {
        id: "register-company.submitButton",
        defaultMessage: "Let's go!",
    },
    error: {
        id: "register-company.error",
        defaultMessage:
            "Something went wrong! Please wait a bit and try again. If the problem persists please contact support",
    },

    // Industries
    industryAccommodationandFoodServices: {
        id: "register-company.industry.x",
        defaultMessage: "Accommodation and Food Services",
    },
    industryAdministrationBusinessSupportandWasteManagementServices: {
        id: "register-company.industry.x",
        defaultMessage: "Administration, Business Support and Waste Management Services",
    },
    industryAgricultureForestryFishingandHunting: {
        id: "register-company.industry.x",
        defaultMessage: "Agriculture, Forestry, Fishing and Hunting",
    },
    industryArtsEntertainmentandRecreation: {
        id: "register-company.industry.x",
        defaultMessage: "Arts, Entertainment and Recreation",
    },
    industryConstruction: {
        id: "register-company.industry.x",
        defaultMessage: "Construction",
    },
    industryEducationalServices: {
        id: "register-company.industry.x",
        defaultMessage: "Educational Services",
    },
    industryFinanceandInsurance: {
        id: "register-company.industry.x",
        defaultMessage: "Finance and Insurance",
    },
    industryHealthcareandSocialAssistance: {
        id: "register-company.industry.x",
        defaultMessage: "Healthcare and Social Assistance",
    },
    industryInformation: {
        id: "register-company.industry.x",
        defaultMessage: "Information",
    },
    industryManufacturing: {
        id: "register-company.industry.x",
        defaultMessage: "Manufacturing",
    },
    industryMining: {
        id: "register-company.industry.x",
        defaultMessage: "Mining",
    },
    industryProfessionalScientificandTechnicalServices: {
        id: "register-company.industry.x",
        defaultMessage: "Professional, Scientific and Technical Services",
    },
    industryRealEstateandRentalandLeasing: {
        id: "register-company.industry.x",
        defaultMessage: "Real Estate and Rental and Leasing",
    },
    industryRetailTrade: {
        id: "register-company.industry.x",
        defaultMessage: "Retail Trade",
    },
    industryTransportationandWarehousing: {
        id: "register-company.industry.x",
        defaultMessage: "Transportation and Warehousing",
    },
    industryUtilities: {
        id: "register-company.industry.x",
        defaultMessage: "Utilities",
    },
    industryWholesaleTrade: {
        id: "register-company.industry.x",
        defaultMessage: "Wholesale Trade",
    },
    industryOther: {
        id: "register-company.industry.x",
        defaultMessage: "Other",
    },
});

export default messages;
