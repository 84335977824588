import React from "react";

import EmailVerificationModal from "@/components/EmailVerificationModal";
import { useRecoveryFlow } from "@/hooks/useRecoveryFlow";
import { Card, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/system";
import { useIntl } from "react-intl";
import messages from "./messages";

const RecoveryPage = () => {
    const recovery = useRecoveryFlow();
    const [email, setEmail] = React.useState("");
    const [didSubmit, setDidSubmit] = React.useState(false);
    const { formatMessage } = useIntl();

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const email = formData.get("email") as string;
        setEmail(email);
        localStorage.setItem("passwordRecoverInitiated", "1");
        await recovery.send(email).then(() => {
            setDidSubmit(true);
        });
    }

    const EmailConfirmationModalWrapper = () => {
        return (
            <EmailVerificationModal
                title={formatMessage(messages.emailTitle, { email })}
                description={formatMessage(messages.emailDescription)}
                redirectUrl={"/"}
            />
        );
    };

    if (didSubmit) {
        return <EmailConfirmationModalWrapper />;
    }

    return (
        <Card sx={{ boxShadow: 4, px: 9, py: 6, pt: 8 }}>
            <Stack alignItems="center" spacing={2} maxWidth="400px">
                <Typography variant="h4">{formatMessage(messages.title)}</Typography>
                <Typography variant="body1">{formatMessage(messages.body)}</Typography>
                <Stack spacing={2} component="form" onSubmit={handleSubmit}>
                    <TextField
                        required
                        name="email"
                        sx={{ minWidth: "350px" }}
                        autoComplete="username"
                        label={formatMessage(messages.emailLabel)}
                        variant="outlined"
                    />
                    <LoadingButton type="submit" variant="outlined" color="primary" loading={recovery.loading}>
                        <span>{formatMessage(messages.submit)}</span>
                    </LoadingButton>
                </Stack>
            </Stack>
        </Card>
    );
};

export default RecoveryPage;
