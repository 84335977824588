import { RestHandler, rest } from "msw";
import { whoAmI } from "../data/ory";
import { OnboardingParameters } from "@/types";

const userSettingsPath = import.meta.env.VITE_AUTHSIDECAR_PUBLIC_URL + "/user-settings";

export const handlers: RestHandler[] = [
    // https://www.ory.sh/docs/kratos/reference/api#tag/frontend/operation/createBrowserSettingsFlow
    rest.post(userSettingsPath, (req, res, ctx) => {
        return req.json().then((data) => {
            const { locale } = data;
            if (locale !== "en-US" && locale !== "nb-NO" && locale !== "de-DE") {
                return res(ctx.status(400));
            }
            whoAmI.identity.metadata_public.language = locale;
            return res(
                ctx.delay(200),
                ctx.status(200),
                ctx.json({
                    locale,
                })
            );
        });
    }),
    rest.get(userSettingsPath, (_, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                locale: whoAmI.identity.metadata_public.language,
            })
        );
    }),

    rest.get("/ory/self-service/settings/browser", (_, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: "270fe26a-8ba3-4749-8f33-96ff12a5290d", // flow id
                ui: {
                    nodes: [
                        {
                            group: "default",
                            attributes: {
                                value: "9de60092-1970-4b38-b061-9c05ef6613ef", // the csrf-token
                            },
                        },
                    ],
                },
            })
        );
    }),
    rest.get("/ory/sessions/whoami", (_, res, ctx) => {
        return res(ctx.status(200), ctx.json(whoAmI));
    }),

    rest.get(`${import.meta.env.VITE_AUTHSIDECAR_PUBLIC_URL}/api/v1/accept-invite`, (req, res, ctx) => {
        const token = req.url.searchParams.get("token");

        const ok: (sso: string | null) => OnboardingParameters["data"] = (
            sso: string | null
        ) => ({
            workspace: {
                id: "ignite",
                display: "Ignite Procurement",
            },
            sso,
            user: {
                id: "4fb27bd2-28a9-4405-9a9b-1c0fae840955",
                email: "Basketball McBasketballFace",
            },
            invitedBy: {
                id: "b3d64f68-f538-47e8-a2f0-593acffd4598",
                display: "John Doe",
            },
        });
        if (token === "pwc") {
            return res(ctx.status(200), ctx.json({ ...ok("pwcId") }));
        } else if (token == "microsoft") {
            return res(ctx.status(200), ctx.json({ ...ok("microsoft") }));
        } else if (token == "password") {
            return res(ctx.delay(2000), ctx.status(200), ctx.json({ ...ok(null) }));
        }
        return res(ctx.status(404), ctx.json({ error: "not found" }));
    }),
];
